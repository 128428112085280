import React, { ReactElement } from 'react';
import { RouteProps, Navigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { Reducers } from '../../store/types';
import { ApplicationPaths, QueryParameterNames } from '../../Config';

type Props = {
    children: ReactElement;
} & Omit<RouteProps, 'children'>;

const AuthorizeRoute: React.FC<Props> = ({ children, ...props }: Props) => {
    const isAuthenticated = useSelector<Reducers, boolean>(state => state.authentication.isAuthenticated);

    const link = document.createElement('a');
    link.href = `${props.path ?? ''}`;
    const returnUrl = `${link.protocol}//${link.host}${link.pathname}${link.search}${link.hash}`.replace('*', '');
    const redirectUrl = `${ApplicationPaths.Login}?${QueryParameterNames.ReturnUrl}=${encodeURIComponent(returnUrl)}`;

    if (isAuthenticated) {
        return children;
     }

     return <Navigate replace={true} to={redirectUrl} />;
};

export default AuthorizeRoute;
