/* eslint-disable @typescript-eslint/consistent-type-assertions */
import { LOGGER_LOG_TYPE } from 'Config';
import { UserProfile } from 'api/account/models/UserProfile';
import ApplicationsService from 'api/applications/ApplicationsService';
import { ApplicationDto } from 'api/applications/models/ApplicationDto';
import UsersService from 'api/users/UsersService';
import Loading from 'common/services/Loading';
import Logger from 'common/services/Logger';
import { useEffect, useState } from 'react';
import { Breadcrumb, Button, Col, Form, Row } from 'react-bootstrap';
import toast from 'react-hot-toast';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { Reducers } from 'store/types';
import styles from './ApplicationScreen.module.scss';
import QuestionYesNo from 'common/components/questionYesNo/QuestionYesNo';
import ScreenTitle from 'common/components/screenTitle/ScreenTitle';

type Props = {
};

const ApplicationScreen: React.FC<Props> = () => {
    const { id, type } = useParams<{ id: string, type: string }>();
    const [isDetails, setIsDetails] = useState<boolean>(type === 'details');
    const userProfile = useSelector<Reducers, UserProfile | null>(state => state.authentication.profile);
    const hasApplicationsWritePolicy = UsersService.hasPolicies(userProfile?.policies || [], ['SETTINGUP_APPLICATIONS_WRITE']);
    const [showRemoveModal, setShowRemoveModal] = useState<boolean>(false);
    const [itemToRemove, setItemToRemove] = useState<ApplicationDto | null>(null);
    const { t } = useTranslation();
    const navigate = useNavigate();
    const [validated, setValidated] = useState(false);
    const [item, setItem] = useState<ApplicationDto>();
    const [formData, setFormData] = useState({
        id: '',
        name: '',
        applicationKey: '',
        rowVersion: ''
    });

    const getData = async () => {
        try {
            let result: any = { id: '', name: '', applicationKey: '' };
            if (id) {
                Loading.show();
                result = await ApplicationsService.getById(id);
                Loading.hide();
            }
            setItem(result);
            setFormData(result);
        } catch (error) {
            Logger.error(LOGGER_LOG_TYPE.REQUEST, 'Couldn\'t get application information', error);
            toast.error(t('messages.error_load_info'));
        }
    };

    useEffect(() => {
        getData().catch(console.error);
    }, [id, type]);

    function handleChange(e: any) {
        const key = e.target.name;
        const value = e.target.value;
        setFormData({ ...formData, [key]: value });
    }

    const navigateTo = (typeUrl?: string, id?: string) => {
        if (typeUrl) {
            navigate(`/application/${typeUrl}/${id}`);
            setIsDetails(typeUrl === 'details');
        } else {
            navigate('/application');
        }
    }
    const onCancelRemove = () => {
        setItemToRemove(null);
        setShowRemoveModal(false);
    };

    const onRemove = async () => {
        if (itemToRemove === null) {
            toast.error(t('messages.record_delete_error'));
            return;
        }

        try {
            await ApplicationsService.remove(itemToRemove);
            onCancelRemove();
            navigateTo();
            toast.success(t('messages.record_delete_success'));
        } catch (error) {
            Logger.error(LOGGER_LOG_TYPE.REQUEST, 'Couldn\'t delete application', error);
            toast.error(t('messages.record_delete_error'));
        }
    };

    const showRemoveItemDialog = async (item: ApplicationDto) => {
        setItemToRemove(item);
        setShowRemoveModal(true);
    }

    const onSubmit = async (event: any) => {
        const form = event.currentTarget;
        event.preventDefault();
        event.stopPropagation();
        setValidated(true);
        if (form.checkValidity() === false) {
            return;
        }
        try {
            Loading.show();
            const model: ApplicationDto = {
                id: formData.id,
                name: formData.name,
                applicationKey: formData.applicationKey,
                rowVersion: formData.rowVersion,
            };
            if (model && model.id) {
                await ApplicationsService.update(model)
                navigateTo('details', id);
            } else if (model) {
                const id = await ApplicationsService.create(model)
                navigateTo('details', id);
            }
            Loading.hide();
            toast.success(t('messages.record_save_success'));
        } catch (error: any) {
            if (error?.response?.status === 409) {
                toast(t('messages.application_already_exists'));
            } else {
                Logger.error(LOGGER_LOG_TYPE.REQUEST, `Couldn't update the application with id: ${form.id}`, error);
                toast.error(t('messages.error_load_info'));
            }
            Loading.hide();
        }
    };

    return (
        <ScreenTitle title={t('applications.title')}>
            <Breadcrumb>
                <Breadcrumb.Item>{t('menu.configuration')}</Breadcrumb.Item>
                <Breadcrumb.Item onClick={() => { navigateTo() }}>
                    {t('applications.title')}
                </Breadcrumb.Item>
                <Breadcrumb.Item active>{t(`common.${type}`)}</Breadcrumb.Item>
            </Breadcrumb>

            <Form onSubmit={onSubmit} noValidate validated={validated}>
                <Row>
                    <Col xs={12}>
                        <Form.Group className="mb-3" controlId="name">
                            <Form.Label>{t('applications.name')}{!isDetails ? '*' : ''}</Form.Label>
                            <Form.Control required name="name" type="text" defaultValue={item?.name} readOnly={isDetails} onChange={handleChange} placeholder={t('applications.name')} />
                        </Form.Group>
                    </Col>
                </Row>
                {item?.id && <Row>
                    <Col xs={12}>
                        <Form.Group className="mb-3" controlId="applicationKey">
                            <Form.Label>{t('applications.key')}</Form.Label>
                            <Form.Control name="applicationKey" type="text" defaultValue={item?.applicationKey} readOnly={true} onChange={handleChange} placeholder={t('applications.key')} />
                        </Form.Group>
                    </Col>
                </Row>
                }
                <div className={styles.buttonsContainer}>
                    <Button variant="secondary" type="button" className={styles.button} onClick={() => navigateTo()}>
                        {t('common.cancel')}
                    </Button>
                    {isDetails && hasApplicationsWritePolicy &&
                        <Button variant="danger" type="button" className={styles.button} onClick={() => showRemoveItemDialog(item as ApplicationDto)} >
                            {t('common.delete')}
                        </Button>
                    }
                    {isDetails && hasApplicationsWritePolicy &&
                        <Button variant="warning" type="button" className={styles.button} onClick={() => navigateTo('edit', item?.id) }>
                            {t('common.edit')}
                        </Button>
                    }
                    {!isDetails && hasApplicationsWritePolicy &&
                        <Button variant="primary" type="submit" className={styles.button}>
                            {t('common.save')}
                        </Button>
                    }
                </div>
            </Form>
            <QuestionYesNo onNo={onCancelRemove} onYes={onRemove} isVisible={showRemoveModal} message={t('messages.remove_record_with_ident', { name: item?.name ?? '' })} />
        </ScreenTitle>
    );
};

export default ApplicationScreen;
