import { LOGGER_LOG_TYPE, STORAGE, TYPE_REQUIREMENT } from 'Config';
import { GroupAccessDto } from 'api/groups/models/GroupDto';
import { useEffect, useRef, useState } from 'react';
import { Button, Col, Form, InputGroup, Modal, Row } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import styles from './AccessModal.module.scss';
import { SelectValueLabel } from 'common/types/SelectValueLabel';
import Loading from 'common/services/Loading';
import GroupsService from 'api/groups/GroupsService';
import toast from 'react-hot-toast';
import Logger from 'common/services/Logger';
import 'react-international-phone/style.css';
import Storage from '../../../common/services/Storage';
import { CountrySelector, CountryIso2, defaultCountries, parseCountry, ParsedCountry } from 'react-international-phone';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faInfoCircle } from '@fortawesome/free-solid-svg-icons';

type Props = {
    show: boolean,
    type: string,
    groupKey: string,
    onClose: (canClose: boolean) => void
};

export enum ContactType {
    EMAIL = 'EMAIL',
    PHONE = 'PHONE'
}

const AccessModal: React.FC<Props> = ({ show, type, groupKey, onClose }: Props) => {
    const { t } = useTranslation();
    const [step, setStep] = useState<Number>(1);
    const submitted = useRef(false);
    const [userValidated, setUserValidated] = useState(false);
    const [codeValidated, setCodeValidated] = useState(false);
    const [contactType] = useState<SelectValueLabel[]>([
        { value: ContactType.EMAIL, label: t('documents.modal.email') ?? '' },
        { value: ContactType.PHONE, label: t('documents.modal.phone') ?? '' }]
    );

    const initialState = {
        groupKey: groupKey,
        type: ContactType.EMAIL,
        name: '',
        phone: '',
        email: '',
        code: ''
    };

    const language = Storage.get(STORAGE.CURRENT_LOCALE_DOCVIEWER)
    const [parsedCountry, setParsedCountry] = useState<ParsedCountry>();
    const [country, setCountry] = useState<CountryIso2>();
    const [formData, setFormData] = useState(initialState);

    function handleChange(e: any) {
        if (e.target.value) {
            if (e.target.value.startsWith(' ')) {
                e.target.value = e.target.value.trim();
            } else {
                e.target.value = e.target.value.replace(/ +(?= )/g,'');
            }
        }

        const key = e.target.name;
        const value = e.target.value;
        setFormData({ ...formData, [key]: value });
    }

    const countries = defaultCountries.filter((country) => {
        const { iso2 } = parseCountry(country);
        return ['pt', 'fr', 'gb', 'de', 'es', 'it'].includes(iso2);
    });

    const cancel = () => {
        if (step === 1) {
            setFormData(initialState)
            onClose(type === TYPE_REQUIREMENT.NOTICE);
        } else {
            setStep(1);
        }
    }

    useEffect(() => {
        let _default = 'fr';
        switch (language) {
            case 'pt': _default = 'pt'; break;
            case 'en': _default = 'gb'; break;
            default:
        }
        setCountry(_default);
        const defaultCountry = defaultCountries.find((country) => parseCountry(country).iso2 === _default);
        if (defaultCountry) {
            setParsedCountry(parseCountry(defaultCountry));
        }
    }, []);

    useEffect(() => {
        submitted.current = false;
    }, [step]);

    const onSubmitUserInfo = async (event: any) => {
        event.preventDefault();
        event.stopPropagation();

        if (submitted.current) {
            return;
        }

        submitted.current = true;
        const form = event.currentTarget;
        setUserValidated(true);
        if (form.checkValidity() === false || !formData.name.trim()) {
            submitted.current = false;
            return;
        }

        try {
            Loading.show();
            const model: GroupAccessDto = {
                groupKey: formData.groupKey,
                name: formData.name,
                type: formData.type
            };

            if (formData.type === ContactType.PHONE && formData.phone) {
                model.phone = ('+' + parsedCountry?.dialCode + formData.phone);
            } else {
                model.email = formData.email;
            }

            await GroupsService.accessDocuments(model);
            setStep(2);
        } catch (error: any) {
            Logger.error(LOGGER_LOG_TYPE.REQUEST, 'Couldn\'t save the group access', error);
            toast.error(t('messages.record_save_error'));
        } finally {
            Loading.hide();
        }
    };

    const onSubmitCode = async (event: any) => {
        const form = event.currentTarget;
        event.preventDefault();
        event.stopPropagation();
        setCodeValidated(true);
        if (form.checkValidity() === false) {
            return;
        }
        try {
            Loading.show();
            const model: GroupAccessDto = {
                groupKey: formData.groupKey,
                name: formData.name,
                type: formData.type,
                phone: '+' + parsedCountry?.dialCode + formData.phone,
                email: formData.email,
                code: formData.code.trim()
            };
            await GroupsService.accessDocumentsCode(model);
            let groups = JSON.parse(Storage.get(STORAGE.LAST_SEEN_GROUPS) ?? '[]');
            groups = [...groups, model.groupKey];
            Storage.set(STORAGE.LAST_SEEN_GROUPS,JSON.stringify(groups));
            onClose(true);
            toast.success(t('messages.record_save_success'));
        } catch (error: any) {
            if (error?.response?.status === 409) {
                toast.error(t('messages.invalid_code'));
            } else {
                Logger.error(LOGGER_LOG_TYPE.REQUEST, 'Couldn\'t save the group access', error);
                toast.error(t('messages.record_save_error'));
            }
        } finally {
            Loading.hide();
        }
    };

    const renderUserInfo = () => {
        return <Form onSubmit={onSubmitUserInfo} noValidate validated={userValidated}>
            <Modal.Body>
                <Row>
                    <Col xs={12} className="mb-3">
                        <h6>{t('documents.modal.subtitle.' + type)}</h6>
                    </Col>
                </Row>
                <Row>
                    <Col xs={12} >
                        <Form.Group className="mb-3" controlId="name">
                            <Form.Label className={styles.labels}>{t('documents.modal.name')} *</Form.Label>
                            <Form.Control autoComplete='false' required name="name" type="text" defaultValue={formData.name}
                                maxLength={200} onChange={handleChange} placeholder={t('documents.modal.name')} />
                        </Form.Group>
                    </Col>
                </Row>
                <Row>
                    <Col xs={4}>
                        <Form.Group controlId="type">
                            <Form.Label>{t('documents.modal.contact')} *</Form.Label>
                            <Form.Select name="type"
                                defaultValue={formData.type}
                                onChange={(e: any) => { handleChange(e); }}>
                                {
                                    contactType.map(l => <option key={l.value} value={l.value}>{l.label}</option>)
                                }
                            </Form.Select>
                        </Form.Group>
                    </Col>
                    {formData.type === ContactType.EMAIL && <Col xs={8}>
                        <Form.Group controlId="email">
                            <Form.Label>&nbsp;</Form.Label>
                            <Form.Control autoComplete='false' required name="email" type="email" defaultValue={formData.email}
                            maxLength={100} onChange={handleChange} placeholder={t('documents.modal.email')} />
                        </Form.Group>
                    </Col>}
                    {formData.type === ContactType.PHONE && <Col xs={8}>
                        <Form.Group controlId="phone">
                            <Form.Label>&nbsp;</Form.Label>
                            <InputGroup>
                               {country && <CountrySelector
                                    buttonStyle={{ height: '100%' }}
                                    countries={countries}
                                    selectedCountry={country}
                                    onSelect={(parsedCountry) => { setParsedCountry(parsedCountry); setCountry(parsedCountry.iso2); }}
                                />}
                                <Form.Control autoComplete='false' type="tel" required name="phone" defaultValue={formData.phone}
                                    maxLength={50} pattern="[0-9]+" onChange={handleChange} placeholder={t('documents.modal.phone')} />
                            </InputGroup>
                        </Form.Group>
                    </Col>}
                </Row>
            </Modal.Body>
            <Modal.Footer>
                <div style={{ flex: 1 }}>
                    <small><i>* {t('messages.fields_required')}</i></small>
                </div>
                {(step === 2 || type !== TYPE_REQUIREMENT.READ) &&
                    <Button variant="secondary" onClick={cancel}>
                        {t('common.cancel')}
                    </Button>
                }
                <Button variant="primary" type="submit">
                    {t('common.continue')}
                </Button>
            </Modal.Footer>
        </Form>
    }

    const renderCode = () => {
        return <Form onSubmit={onSubmitCode} noValidate validated={codeValidated}>
            <Modal.Body>
                <Row>
                    <Col xs={12}>
                        {formData.type === ContactType.EMAIL
                            ? t('documents.modal.email_validation_message', { email: formData.email })
                            : t('documents.modal.phone_validation_message', { phone: '+' + parsedCountry?.dialCode + ' ' + formData.phone })
                        }
                        <p className="mb-3">
                            <FontAwesomeIcon className={styles.navbarTogglerIcon} icon={faInfoCircle} />
                            <i> {t('documents.modal.validation_info')}</i>
                        </p>
                    </Col>
                </Row>
                <Row>
                    <Col xs={12} >
                        <Form.Group className="mb-3" controlId="code">
                            <Form.Label className={styles.labels}>{t('documents.modal.code')} *</Form.Label>
                            <Form.Control autoComplete='false' required name="code" type="text" onChange={handleChange} placeholder={t('documents.modal.code_placeholder')} />
                        </Form.Group>
                    </Col>
                </Row>
            </Modal.Body>
            <Modal.Footer>
                <div style={{ flex: 1 }}>
                    <small><i>* {t('messages.fields_required')}</i></small>
                </div>
                <Button variant="secondary" onClick={cancel}>
                    {t('common.cancel')}
                </Button>
                <Button variant="primary" type="submit">
                    {t('documents.modal.validate')}
                </Button>
            </Modal.Footer>
        </Form>
    }

    return (
        <Modal backdrop='static' size='lg' centered show={show} onHide={cancel}>
            <Modal.Header closeButton={step === 2 || type !== TYPE_REQUIREMENT.READ}>
                <Modal.Title> {t('documents.modal.title.' + type)}</Modal.Title>
            </Modal.Header>
            {step === 1 && renderUserInfo()}
            {step === 2 && renderCode()}
        </Modal>
    );
}

export default AccessModal;
