import React, { useCallback, useMemo, useState } from 'react';
import { Page, Document, pdfjs } from 'react-pdf'
import { File } from 'react-pdf/dist/cjs/shared/types';
import { useResizeObserver } from '@wojtekmaj/react-hooks';
import LoadingSpinner from '../loading/LoadingSpinner';
import styles from './PDFViewer.module.scss';
import { useTranslation } from 'react-i18next';
import { Col, Row } from 'react-bootstrap';

interface Props {
  file?: File;
}
const PDFViewer: React.FunctionComponent<Props> = ({ file }: Props) => {
  const [numPages, setNumPages] = useState<number>();
  const [pageNumber, setPageNumber] = useState<number>(1);
  const options = useMemo(() => { return { cMapUrl: '/lib/pdfjs-dist/cmaps/', standardFontDataUrl: '/lib/pdfjs-dist/standard_fonts/' } },[]);
  const minWidth = 200;
  const [containerRef, setContainerRef] = useState<HTMLElement | null>(null);
  const [containerWidth, setContainerWidth] = useState<number>();
  const resizeObserverOptions = {};
  const { t } = useTranslation();

  const onResize = useCallback<ResizeObserverCallback>((entries) => {
    const [entry] = entries;

    if (entry && entry.contentRect.width > 0) {
      setContainerWidth(entry.contentRect.width);
    }
  }, []);

  useResizeObserver(containerRef, resizeObserverOptions, onResize);
  pdfjs.GlobalWorkerOptions.workerSrc = '/lib/pdfjs-dist/build/pdf.worker.min.js';

  const onPageLoadSuccess = () => {
    // console.log('onPageLoadSuccess');
  }

  const onDocumentLoadSuccess = ({ numPages }: { numPages: number }): void => {
    setNumPages(numPages);
  }

  return (
    <div style={ { flex: '1' } } ref={setContainerRef}>
      <Document
        file={file}
        onLoadError={(error) => console.log('Error while loading document! ' + error.message)}
        onLoadSuccess={onDocumentLoadSuccess}
        renderMode="canvas"
        loading={<div className={styles.container}><Row><Col><LoadingSpinner /></Col></Row><Row><Col>{t('common.loading_files')}</Col></Row></div>}
        options={options}
      >
        {numPages ?
         Array.from(new Array(numPages), (page, index) => (
            <Page
              loading={''}
              key={`page_${index + 1}`}
              pageNumber={index + 1}
              renderAnnotationLayer={false}
              renderTextLayer={false}
              onLoadSuccess={onPageLoadSuccess}
              width={containerWidth ? Math.max(containerWidth, minWidth) : minWidth}
            />
          ))
          : undefined}
      </Document>
    </div>
  )
}
export default PDFViewer;
