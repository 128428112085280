import { DATE_TIME_FORMAT_DEFAULT, TYPE_REQUIREMENT } from 'Config';
import { useState } from 'react';
import { Col, Container, Offcanvas, Row } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import styles from './HistoryBar.module.scss';
import { faClockRotateLeft, faSortAmountUpAlt, faFileArrowDown, faDownload } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { GroupAccessDto } from 'api/groups/models/GroupDto';
import DateFormat from 'common/components/dateFormat/dateFormat';
import LoadingSpinner from 'common/components/loading/LoadingSpinner';

type Props = {
    items: GroupAccessDto[],
    type: string,
    canDownload: boolean,
    canSeeHistory: boolean,
    downloading: boolean,
    onScrollToTop: () => void,
    onDownloadZip: () => void,
    onDownloadMedia: () => void,
};

const HistoryBar: React.FC<Props> = ({ items, type, downloading, canDownload, canSeeHistory, onScrollToTop, onDownloadZip, onDownloadMedia }: Props) => {
    const { t } = useTranslation();
    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    return (
        <Container fluid className={styles.bar}>
            <Row>
                <Col xs="12">
                   { canSeeHistory && <FontAwesomeIcon className={styles.navbarTogglerIcon} icon={faClockRotateLeft} onClick={handleShow} /> }
                   { canDownload && <div style={{ display: 'flex', justifyContent: 'flex-end', flexDirection: 'column' }}>
                        { !downloading && <>
                            <FontAwesomeIcon className={styles.navbarTogglerIcon} icon={faFileArrowDown} onClick={onDownloadMedia} />
                            {/* <FontAwesomeIcon className={styles.navbarTogglerIcon} icon={faDownload} onClick={onDownloadZip} /> */}
                        </> }
                        { downloading && <LoadingSpinner /> }
                    </div> }
                </Col>
                <Col xs="12" style={{ display: 'flex' }}>
                    <div style={{ display: 'flex', alignItems: 'flex-end' }}>
                        <FontAwesomeIcon className={styles.navbarTogglerIcon} icon={faSortAmountUpAlt} onClick={onScrollToTop} />
                    </div>
                </Col>
            </Row>
            <Offcanvas show={show} onHide={handleClose} placement={'end'}>
                <Offcanvas.Header closeButton>
                    <Offcanvas.Title>{t('documents.bar.title')}</Offcanvas.Title>
                </Offcanvas.Header>
                <Offcanvas.Body>
                    <div className={styles.listGroup}>
                        {items.map((item, index) => {
                            return <div className={styles.listGroupItem} key={item.id}>
                                <div>
                                    <h5 className="mb-1">
                                        <FontAwesomeIcon icon={faClockRotateLeft} className={styles.listGroupItemIcon} />
                                        {type === TYPE_REQUIREMENT.NOTICE ? t('documents.bar.NOTICE') : t('documents.bar.READ') }
                                    </h5>
                                </div>
                                <div className={`${styles.listGroupItemDescription} ${index === (items.length - 1) ? styles.noBorder : ''}`}>
                                    <p className="mb-0"><DateFormat value={item.date ?? new Date()} format={DATE_TIME_FORMAT_DEFAULT} /></p>
                                    <p className="mb-0">{item.name}</p>
                                    <p className="mb-0">{item.email}</p>
                                    <p className="mb-0">{item.phone}</p>
                                </div>
                            </div>
                        })}
                    </div>
                </Offcanvas.Body>
            </Offcanvas>
        </Container>
    );
}

export default HistoryBar;
