import { Button, Col, Offcanvas, Row } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import styles from './AccessBar.module.scss';

type Props = {
    show: boolean,
    onClose: (canClose: boolean) => void
    onClick: () => void
};

const AccessBar: React.FC<Props> = ({ show, onClose, onClick }: Props) => {
    const { t } = useTranslation();
    const handleClose = () => onClose(true);
    return (
        <Offcanvas show={show} backdrop={false} onHide={handleClose} placement={'bottom'} className={styles.offcanvasBottom}>
            <Offcanvas.Header closeButton className={styles.offcanvasBottomHeader}></Offcanvas.Header>
            <Offcanvas.Body className={styles.offcanvasBottomBody}>
                <Row>
                    <Col xs="12" md={10}>
                        <h6>{t('documents.bottomBar.title')}</h6>
                    </Col>
                    <Col xs="12" md={2}>
                        <Button variant="primary" onClick={onClick}>{t('documents.bottomBar.button')}</Button>
                    </Col>
                </Row>
            </Offcanvas.Body>
        </Offcanvas>
    );
}

export default AccessBar;
