import React from 'react';
import Moment from 'moment';
import { DATE_FORMAT_DEFAUT, DEFAULT_LANGUAGE, STORAGE } from '../../../Config';
import Storage from '../../services/Storage';

type Props = {
    value: string | Date;
    format?: string;
};

const DateFormat: React.FC<Props> = ({ value, format }: Props) => {
    Moment.locale(Storage.get(STORAGE.CURRENT_LOCALE_DOCVIEWER) ?? DEFAULT_LANGUAGE);
    return (
        <span>
            {Moment(value).format(format ?? DATE_FORMAT_DEFAUT)}
        </span>
    );
};

export default DateFormat;
