import { Paged } from 'api/common/types/Page';
import Http from '../../common/services/Http';
import { GroupAccessDto, GroupDto } from './models/GroupDto';
import { GroupSearchCriteria } from './models/GroupSearchCriteria';
import CustomFile from 'common/models/CustomFile';

class GroupsService {
    public getList(criteria: GroupSearchCriteria) {
        return Http.get<Paged<GroupDto>>('groups', criteria);
    }

    public getById(id: string) {
        return Http.get<GroupDto>('groups/' + id);
    }

    public get (key: string) {
        return Http.get<GroupDto>(`groups/${key}`);
    }

    public getType (key: string) {
        return Http.get<string>(`groups/type/${key}`);
    }

    public remove(model: GroupDto) {
        return Http.put(`groups/${model.id}/deactivate`, model);
    }

    public create(model: GroupDto, attachment: CustomFile[]) {
        return Http.postWithFile<string>('groups/add-group', model, attachment);
    }

    public update(model: GroupDto, attachment: CustomFile[]) {
        return Http.putWithFile<string>('groups', model, attachment);
    }

    public accessDocuments (model: GroupAccessDto) {
        return Http.post<GroupAccessDto>('groups/access-documents', model);
    }

    public accessDocumentsCode (model: GroupAccessDto) {
        return Http.post<GroupAccessDto>('groups/access-documents-code', model);
    }

    public getAccessDocumentsHistory (groupId: string) {
        return Http.get<GroupAccessDto[]>(`groups/get-access-documents-history/${groupId}`);
    }

    public downloadByGroup(groupId: string) {
        return Http.getWithConfig<Blob>(`groups/download-by-group/${groupId}`,undefined, { responseType: 'blob' });
    }

    public downloadByMedia(mediaId: string) {
        return Http.getWithConfig<Blob>(`groups/download-by-media/${mediaId}`,undefined, { responseType: 'blob' });
    }
}

export default new GroupsService();
