import { MediaDto } from 'api/medias/MediasDto';

export interface GroupDto {
    id: string;
    type: GroupType;
    canDownload: boolean;
    canSeeHistory: boolean;
    medias?: MediaDto[];
    applicationId: string;
    applicationName?: string;
    createdDate?: Date;
    attachmentsToRemove: string[];
    publicUrl?: string;
}

export interface GroupAccessDto {
    id?: string;
    groupId?: string;
    groupKey?: string;
    type?: string;
    name: string;
    phone?: string;
    email?: string;
    code?: string;
    date?: Date;
}

export enum GroupType {
    READ = 'READ',
    NOTICE = 'NOTICE'
}
