import React from 'react';
import { useNavigate } from 'react-router-dom';
import Sidebar from 'react-bootstrap-sidebar-menu';
import styles from './Sidebar.module.scss';
import { ApplicationName, POLICIES } from 'Config';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Logo from 'assets/svg/viewer.svg';
export interface SideBarItem {
    text: string;
    url?: string;
    icon?: any;
    policies: (keyof typeof POLICIES)[];
    type?: 'OR' | 'AND';
    items?: SideBarItem[];
}

type Props = {
    items: SideBarItem[];
};

const SideBar: React.FunctionComponent<Props> = ({ items }: Props) => {
    const navigate = useNavigate();
    function handleSelect(url: any) {
        navigate(url);
    }

    const renderWithSubItems = (item: SideBarItem, index: number) => {
        return (
            <Sidebar.Sub eventKey={0} key={`subitem_${index}`}>
                <Sidebar.Sub.Toggle key={`toogle_item_${index}`}>
                    <Sidebar.Nav.Icon key={`icon_item_${index}`} />
                    <Sidebar.Nav.Title key={`title_item_${index}`}>{item.text}</Sidebar.Nav.Title>
                </Sidebar.Sub.Toggle>
                <Sidebar.Sub.Collapse key={`c_item_${index}`}>
                    <Sidebar.Nav key={`nav_c_item_${index}`}>
                        {item.items?.map((subitem, i) => (
                            <Sidebar.Nav.Link key={`link_subitem_${index}_${subitem.text}`} onSelect={() => handleSelect(subitem.url)} eventKey={subitem.text} >
                                <Sidebar.Nav.Icon key={`icon_subitem_${index}_${i}`}><FontAwesomeIcon icon={subitem.icon} /></Sidebar.Nav.Icon>
                                <Sidebar.Nav.Title key={`title_subitem_${index}_${i}`}>{subitem.text}</Sidebar.Nav.Title>
                            </Sidebar.Nav.Link>
                        ))}
                    </Sidebar.Nav>
                </Sidebar.Sub.Collapse>
            </Sidebar.Sub>
        );
    };

    const renderItem = (item: SideBarItem, index: number) => {
        return (
            <Sidebar.Nav.Link key={`item_${index}`} onSelect={() => handleSelect(item.url)} eventKey={item.text}>
                <Sidebar.Nav.Icon key={`icon_item_${index}`}><FontAwesomeIcon icon={item.icon} /></Sidebar.Nav.Icon>
                <Sidebar.Nav.Title key={`title_item_${index}`}>{item.text}</Sidebar.Nav.Title>
            </Sidebar.Nav.Link>
        );
    };

    return (
        <Sidebar expand="sm" className={styles.sidebar} variant="dark" bg="dark">
            <Sidebar.Collapse>
                <Sidebar.Header>
                    <Sidebar.Brand><img src={Logo} style={ { paddingRight: '5px' } }/>{ApplicationName}</Sidebar.Brand>
                    <Sidebar.Toggle />
                </Sidebar.Header>
                <Sidebar.Body>
                    <Sidebar.Nav>
                        {items.map((item, i) => (
                            item.items ? renderWithSubItems(item, i) : renderItem(item, i)
                        ))}
                    </Sidebar.Nav>
                </Sidebar.Body>
            </Sidebar.Collapse>
        </Sidebar>
    );
};

export default SideBar;
