import styles from './NavBar.module.scss';
import { Container, Nav, NavDropdown, Navbar } from 'react-bootstrap';
import Logo from 'assets/svg/logo.svg';
import { Reducers } from 'store/types';
import { UserProfile } from 'api/account/models/UserProfile';
import { ApplicationPaths, DEFAULT_LANGUAGE, LOGGER_LOG_TYPE, STORAGE } from 'Config';
import { useNavigate } from 'react-router-dom';
import { useEffect, useState } from 'react';
import LanguagesService from 'api/languages/LanguagesService';
import { SelectValueLabel } from 'common/types/SelectValueLabel';
import AccountService from 'api/account/AccountService';
import { setProfile } from 'store/authentication/action';
import Logger from 'common/services/Logger';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import i18n from 'common/services/I18n';
import Storage from 'common/services/Storage';
import useNoInitialEffect from 'common/hooks/useNoInitialEffect';

const NavBar = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const loggedUser = useSelector<Reducers, UserProfile | null>(state => state.authentication.profile);
    const [langs, setLangs] = useState<SelectValueLabel[]>([]);
    const [lang, setLang] = useState<SelectValueLabel>();

    const listLanguages = ['pt', 'fr', 'en'];

    const changeLang = (lang: SelectValueLabel) => {
        if (loggedUser) {
            AccountService.changeLang(lang)
                .finally(() => {
                    getProfile().catch(console.error);
                    setLang(lang);
                });
        } else {
            setLang(lang);
        }
    }

    const getProfile = async () => {
        try {
            const profile = await AccountService.profile();
            dispatch(setProfile(profile) as any);
        } catch (error) {
            Logger.error(LOGGER_LOG_TYPE.REQUEST, 'Couldn\'t get user profile', error);
        }
    }

    useEffect(() => {
        LanguagesService.GetAllForSelectItem()
            .then((data) => {
                setLangs(data);
                if (loggedUser) {
                    setLang(data.filter(l => l.value === loggedUser?.languageId)[0]);
                } else {
                    let language = Storage.get(STORAGE.CURRENT_LOCALE_DOCVIEWER);
                    if (language == null) {
                        const browserLanguage = window.navigator.language.toLowerCase().split('-')[0];
                        const langByBrowser = listLanguages.find(x => browserLanguage.includes(x.toLowerCase()));
                        language = langByBrowser ?? DEFAULT_LANGUAGE;
                    }
                    setLang(data.filter(l => language === l.label || l.value === language)[0]);
                }
            })
            .catch(() => Logger.error(LOGGER_LOG_TYPE.REQUEST, 'Couldn\'t get languages'));
    }, []);

    useNoInitialEffect(() => {
        if (lang && !loggedUser) {
            i18n.changeLanguage(lang.label);
        }
    }, [lang]);

    return <Navbar className={`main-header ${styles.NavBar}`} expand="lg" data-bs-theme="light">
        <Container fluid style={{ justifyContent: 'flex-end' }}>
            {loggedUser && <Navbar.Collapse style={{ flexGrow: 'unset', padding: '0 10px' }}>
                <Nav>
                    <NavDropdown title={loggedUser?.realName} menuVariant="light">
                        <NavDropdown.Item onClick={() => navigate('/account/details')}>{t('common.profile')}</NavDropdown.Item>
                        <NavDropdown.Item onClick={() => navigate(`${ApplicationPaths.LogOut}`)}>{t('common.logout')}</NavDropdown.Item>
                    </NavDropdown>
                </Nav>
            </Navbar.Collapse>}
            {lang && <Navbar.Collapse style={{ flexGrow: 'unset', padding: '0 10px' }}>
                <Nav>
                    <NavDropdown title={lang?.fullLabel} menuVariant="light">
                        {langs.map(l => {
                            return <NavDropdown.Item onClick={() => changeLang(l)} key={l.value}>{l.fullLabel}</NavDropdown.Item>
                        })}
                    </NavDropdown>
                </Nav>
            </Navbar.Collapse>
            }
            <Navbar.Brand href="http://www.sparkleit.pt" className={!loggedUser ? styles['mr-3'] : ''}>
                <img src={Logo} className={styles.logo} />
            </Navbar.Brand>
        </Container>
    </Navbar>;
};

export default NavBar;
