/* eslint-disable @typescript-eslint/consistent-type-assertions */
import { DATE_TIME_FORMAT_DEFAULT, LOGGER_LOG_TYPE, TYPE_REQUIREMENT } from 'Config';
import { UserProfile } from 'api/account/models/UserProfile';
import GroupsService from 'api/groups/GroupsService';
import { GroupAccessDto, GroupDto, GroupType } from 'api/groups/models/GroupDto';
import UsersService from 'api/users/UsersService';
import Loading from 'common/services/Loading';
import Logger from 'common/services/Logger';
import { useEffect, useState } from 'react';
import { Breadcrumb, Button, Col, Form, Row, Tab, Tabs } from 'react-bootstrap';
import toast from 'react-hot-toast';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { Reducers } from 'store/types';
import styles from './GroupScreen.module.scss';
import QuestionYesNo from 'common/components/questionYesNo/QuestionYesNo';
import CustomFile from 'common/models/CustomFile';
import ScreenTitle from 'common/components/screenTitle/ScreenTitle';
import { SelectValueLabel } from 'common/types/SelectValueLabel';
import ApplicationsService from 'api/applications/ApplicationsService';
import FileSelector from 'common/components/fileSelector/FileSelector';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faClockRotateLeft, faEye } from '@fortawesome/free-solid-svg-icons';
import DateFormat from 'common/components/dateFormat/dateFormat';

type Props = {
};

const GroupScreen: React.FC<Props> = () => {
    const { id, type } = useParams<{ id: string, type: string }>();
    const [isDetails, setIsDetails] = useState<boolean>(type === 'details');
    const userProfile = useSelector<Reducers, UserProfile | null>(state => state.authentication.profile);
    const hasGroupsWritePolicy = UsersService.hasPolicies(userProfile?.policies || [], ['SETTINGUP_GROUPS_WRITE']);
    const [showRemoveModal, setShowRemoveModal] = useState<boolean>(false);
    const [itemToRemove, setItemToRemove] = useState<GroupDto | null>(null);
    const [applications, setApplications] = useState<SelectValueLabel[]>([]);
    const [attachments, setAttachments] = useState<CustomFile[]>([]);
    const [removedAttachments, setRemovedAttachments] = useState<string[]>([]);
    const [historic, setHistoric] = useState<GroupAccessDto[]>([]);
    const { t } = useTranslation();
    const navigate = useNavigate();
    const [validated, setValidated] = useState(false);
    const [item, setItem] = useState<GroupDto>();
    const [groupTypes] = useState<SelectValueLabel[]>([
        { value: GroupType.READ, label: t('groups.types.READ') ?? '' },
        { value: GroupType.NOTICE, label: t('groups.types.NOTICE') ?? '' }]);

    const [formData, setFormData] = useState({
        id: '',
        type: GroupType.READ,
        canDownload: false,
        canSeeHistory: false,
        applicationId: ''
    });

    const getData = async () => {
        try {
            Loading.show();
            const result: GroupDto = { id: '', type: GroupType.READ, canDownload: false, canSeeHistory: false, applicationId: '', attachmentsToRemove: [] };
            const apps = await ApplicationsService.getMyApplications();
            setApplications(apps);
            if (apps && apps.length > 0) {
                result.applicationId = apps[0].value ?? '';
            }
            if (id) {
                const [result, historic] = await Promise.all([GroupsService.getById(id), GroupsService.getAccessDocumentsHistory(id)]);
                if (result && result.medias) {
                    const attachments: any[] = [];
                    result.medias.forEach(m => {
                        attachments.push({ id: m.id, name: m.fileName ?? '', url: m.url ?? '' });
                    });
                    setAttachments(attachments);
                }
                setHistoric(historic);
                setItem(result);
                setFormData(result);
                console.log(result);
            } else {
                setItem(result);
                setFormData(result);
            }
        } catch (error) {
            Logger.error(LOGGER_LOG_TYPE.REQUEST, 'Couldn\'t get group information', error);
            toast.error(t('messages.error_load_info'));
        } finally {
            Loading.hide();
        }
    };

    useEffect(() => {
        getData().catch(console.error);
    }, [id, type]);

    function handleChange(e: any) {
        const key = e.target.name;
        const value = e.target.value;
        setFormData({ ...formData, [key]: value });
    }

    const navigateTo = (typeUrl?: string, id?: string) => {
        if (typeUrl) {
            navigate(`/group/${typeUrl}/${id}`);
            setIsDetails(typeUrl === 'details');
        } else {
            navigate('/group');
        }
    }
    const onCancelRemove = () => {
        setItemToRemove(null);
        setShowRemoveModal(false);
    };

    const onRemove = async () => {
        if (itemToRemove === null) {
            toast.error(t('messages.record_delete_error'));
            return;
        }

        try {
            await GroupsService.remove(itemToRemove);
            onCancelRemove();
            navigateTo();
            toast.success(t('messages.record_delete_success'));
        } catch (error) {
            Logger.error(LOGGER_LOG_TYPE.REQUEST, 'Couldn\'t delete group', error);
            toast.error(t('messages.record_delete_error'));
        }
    };

    const showRemoveItemDialog = async (item: GroupDto) => {
        setItemToRemove(item);
        setShowRemoveModal(true);
    }

    const onFilesChanged = (files: CustomFile[]) => {
        setAttachments(files);
    }

    const onRemoveFile = (file: CustomFile) => {
        if (file.id) {
            removedAttachments.push(file.id);
        }
    }

    const onSubmit = async (event: any) => {
        const form = event.currentTarget;
        event.preventDefault();
        event.stopPropagation();
        setValidated(true);
        if (form.checkValidity() === false) {
            return;
        }
        try {
            Loading.show();
            const model: GroupDto = {
                id: formData.id,
                type: formData.type,
                canDownload: formData.canDownload,
                canSeeHistory: formData.canSeeHistory,
                applicationId: formData.applicationId,
                attachmentsToRemove: removedAttachments
            };
            if (model && model.id) {
                await GroupsService.update(model, attachments)
                navigateTo('details', id);
            } else if (model) {
                const id = await GroupsService.create(model, attachments)
                navigateTo('details', id);
            }
            Loading.hide();
            toast.success(t('messages.record_save_success'));
        } catch (error: any) {
            Logger.error(LOGGER_LOG_TYPE.REQUEST, `Couldn't update the group with id: ${form.id}`, error);
            toast.error(t('messages.record_save_error'));
            Loading.hide();
        }
    };

    return (
        <ScreenTitle title={t('groups.title')}>
            <Breadcrumb>
                <Breadcrumb.Item onClick={() => { navigateTo() }}>
                    {t('groups.title')}
                </Breadcrumb.Item>
                <Breadcrumb.Item active>{t(`common.${type}`)}</Breadcrumb.Item>
            </Breadcrumb>
            <Tabs defaultActiveKey="main" className="mb-3" fill>
                <Tab eventKey="main" title={t('groups.tab.data')}>
                    <Form onSubmit={onSubmit} noValidate validated={validated}>
                        <Row>
                            <Col xs={12}>
                                <Form.Group className="mb-3" controlId="applicationId" key={item?.applicationId}>
                                    <Form.Label>{t('groups.application')}{!isDetails ? '*' : ''}</Form.Label>
                                    <Form.Select required name="applicationId" defaultValue={item?.applicationId} disabled={isDetails || !hasGroupsWritePolicy}
                                        onChange={(e: any) => { handleChange(e); }}>
                                        {applications && applications.map(c => <option key={c.value} value={c.value}>{c.label}</option>)}
                                    </Form.Select>
                                </Form.Group>
                            </Col>
                        </Row>
                        <Row>
                            <Col xs={12}>
                                <Form.Group className="mb-3" controlId="type" key={item?.type}>
                                    <Form.Label>{t('groups.type')}{!isDetails ? '*' : ''}</Form.Label>
                                    <Form.Select required name="type" defaultValue={item?.type} disabled={isDetails || !hasGroupsWritePolicy}
                                        onChange={(e: any) => { handleChange(e); }}>
                                        {groupTypes && groupTypes.map(c => <option key={c.value} value={c.value}>{c.label}</option>)}
                                    </Form.Select>
                                </Form.Group>
                            </Col>
                        </Row>
                        <Row>
                            <Col xs={12} md={4} key={'canDownload'}>
                                <Form.Group className="mb-3" controlId={'enabled'}>
                                    <Form.Label>{t('groups.can_download')}</Form.Label>
                                    <Form.Check name={'canDownload'} type="switch" checked={formData.canDownload}
                                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                            setFormData({ ...formData, canDownload: e.target.checked });
                                        }}
                                        disabled={isDetails} />
                                </Form.Group>
                            </Col>
                            <Col xs={12} md={4} key={'canSeeHistory'}>
                                <Form.Group className="mb-3" controlId={'canSeeHistory'}>
                                    <Form.Label>{t('groups.can_see_history')}</Form.Label>
                                    <Form.Check name={'canSeeHistory'} type="switch" checked={formData.canSeeHistory}
                                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                            setFormData({ ...formData, canSeeHistory: e.target.checked });
                                        }}
                                        disabled={isDetails} />
                                </Form.Group>
                            </Col>
                        </Row>
                        <Row>
                            <Col xs={6} lg={4} xl={3}>
                                <Form.Group className="mb-3" controlId={'documents'}>
                                    <Form.Label>{t('groups.documents')}{item && item.id && <FontAwesomeIcon icon={faEye} className={styles.viewerIcon} onClick={ () => window.open(item.publicUrl, '_blank') } />}</Form.Label>
                                    <FileSelector
                                        isMulti={true}
                                        inLine={true}
                                        isDetails={isDetails}
                                        initialFiles={attachments}
                                        onRemoveFile={onRemoveFile}
                                        onFilesChanged={onFilesChanged}
                                        accept="application/pdf" />
                                </Form.Group>
                            </Col>
                        </Row>
                        <div className={styles.buttonsContainer}>
                            <Button variant="secondary" type="button" className={styles.button} onClick={() => navigateTo()}>
                                {t('common.cancel')}
                            </Button>
                            {isDetails && hasGroupsWritePolicy &&
                                <Button variant="danger" type="button" className={styles.button} onClick={() => showRemoveItemDialog(item as GroupDto)} >
                                    {t('common.delete')}
                                </Button>
                            }
                            {isDetails && hasGroupsWritePolicy &&
                                <Button variant="warning" type="button" className={styles.button} onClick={() => { navigateTo('edit', item?.id); }}>
                                    {t('common.edit')}
                                </Button>
                            }
                            {!isDetails && hasGroupsWritePolicy &&
                                <Button variant="primary" type="submit" className={styles.button}>
                                    {t('common.save')}
                                </Button>
                            }
                        </div>
                    </Form>
                    <QuestionYesNo onNo={onCancelRemove} onYes={onRemove} isVisible={showRemoveModal} message={t('messages.remove_record_with_ident')} />
                </Tab>
                <Tab eventKey="history" title={t('groups.tab.historic')}>
                    <div className={styles.listGroup}>
                            {historic.map((historicItem, index) => {
                                return <div className={styles.listGroupItem} key={historicItem.id}>
                                    <div>
                                        <h5 className="mb-1">
                                            <FontAwesomeIcon icon={faClockRotateLeft} className={styles.listGroupItemIcon} />
                                            {type === TYPE_REQUIREMENT.NOTICE ? t('documents.bar.NOTICE') : t('documents.bar.READ') }
                                        </h5>
                                    </div>
                                    <div className={`${styles.listGroupItemDescription} ${index === (historic.length - 1) ? styles.noBorder : ''}`}>
                                        <p className="mb-0"><DateFormat value={historicItem.date ?? new Date()} format={DATE_TIME_FORMAT_DEFAULT} /></p>
                                        <p className="mb-0">{historicItem.name}</p>
                                        <p className="mb-0">{historicItem.email}</p>
                                        <p className="mb-0">{historicItem.phone}</p>
                                    </div>
                                </div>
                            })}
                        </div>
                </Tab>
            </Tabs>
        </ScreenTitle>
    );
};

export default GroupScreen;
