/* eslint-disable @typescript-eslint/brace-style */
import { useNavigate, useLocation } from 'react-router-dom';
import { UserProfile } from 'api/account/models/UserProfile';
import { Paged } from 'api/common/types/Page';
import UsersService from 'api/users/UsersService';
import ListingTable, { ListingTableColumn } from 'common/components/listingTable/ListingTable';
import PaginationWithInfo from 'common/components/pagination/PaginationWithInfo';
import QuestionYesNo from 'common/components/questionYesNo/QuestionYesNo';
import Logger from 'common/services/Logger';
import { DATE_TIME_FORMAT_DEFAULT, DEFAULT_PAGINATION_ITEMS_PER_PAGE, LOGGER_LOG_TYPE } from 'Config';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { Reducers } from 'store/types';
import styles from './GroupsScreen.module.scss';
import ScreenTitle from 'common/components/screenTitle/ScreenTitle';
import toast from 'react-hot-toast';
import { Button, Container, Dropdown, DropdownButton, Stack, Form, Card, Breadcrumb } from 'react-bootstrap';
import Loading from 'common/services/Loading';
import { GroupDto } from 'api/groups/models/GroupDto';
import { GroupSearchCriteria } from 'api/groups/models/GroupSearchCriteria';
import GroupsService from 'api/groups/GroupsService';
import DateFormat from 'common/components/dateFormat/dateFormat';
import { faFile } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

type Props = {
};

const GroupsScreen: React.FC<Props> = () => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const location = useLocation();
    const [criteria, setCriteria] = useState<GroupSearchCriteria>({
        itemsPerPage: DEFAULT_PAGINATION_ITEMS_PER_PAGE,
        page: 1,
        orderBy: 'desc',
        orderColumn: 'g.created_date'
    });
    const [groupsPage, setGroupsPage] = useState<Paged<GroupDto>>();
    const userProfile = useSelector<Reducers, UserProfile | null>(state => state.authentication.profile);
    const hasgroupsWritePolicy = UsersService.hasPolicies(userProfile?.policies || [], ['SETTINGUP_GROUPS_WRITE']);
    const [filters, setFilters] = useState({
        allIn: ''
    });
    const getData = async () => {
        try {
            Loading.show();
            const page = await GroupsService.getList(criteria);
            setGroupsPage(page);
        } catch (error) {
            Logger.error(LOGGER_LOG_TYPE.REQUEST, 'Couldn\'t get groups list', error);
            toast.error(t('messages.error_load_info'));
        } finally {
            Loading.hide();
        }
    }

    const renderTableActionCell = (row: GroupDto) => {
        return <DropdownButton id="dropdown-basic-button" title="..." size="sm" variant="outline-secondary">
            <Dropdown.Item onClick={event => navigateTo(event,'details', row.id)}>{t('common.details')}</Dropdown.Item>
            {hasgroupsWritePolicy && <>
                <Dropdown.Item onClick={event => navigateTo(event,'edit', row.id)}>{t('common.edit')}</Dropdown.Item>
                <Dropdown.Item onClick={() => showRemoveItemDialog(row)}>{t('common.delete')}</Dropdown.Item>
            </>
            }
        </DropdownButton>
    };

    const onTableFilter = (field: string, isFilterAsc: boolean) => {
        setCriteria({ ...criteria, page: 1, orderBy: (isFilterAsc ? 'asc' : 'desc'), orderColumn: field });
    }

    const tableColumns: ListingTableColumn<GroupDto>[] = [
        {
            name: t('groups.list.date') ?? '',
            field: 'createdDate',
            renderCell: row => <div><DateFormat value={row.createdDate ?? new Date()} format={DATE_TIME_FORMAT_DEFAULT} /></div>,
            onSearch: onTableFilter,
            hideOn: ['sm'],
            searchField: 'g.created_date'
        },
        {
            field: 'applicationName',
            name: t('groups.list.application_name') ?? '',
            onSearch: onTableFilter,
            searchField: 'a.name'
        },
        {
            name: t('groups.list.files') ?? '',
            renderCell: row => <div>{row.medias?.map(x => <div key={x.id}><FontAwesomeIcon icon={faFile}></FontAwesomeIcon><span style={ { padding: '0 10px' } }>{x.fileName}</span></div>)} </div>,
            width: 'fill'
        },
        {
            name: '',
            width: '60px',
            cellAlignment: 'right',
            preventClick: true,
            renderCell: renderTableActionCell,
            cellStyle: { overflow: 'unset' }
        },
    ];

    const [showRemoveModal, setShowRemoveModal] = useState<boolean>(false);
    const [itemToRemove, setItemToRemove] = useState<GroupDto | null>(null);

    const showRemoveItemDialog = async (item: GroupDto) => {
        setItemToRemove(item);
        setShowRemoveModal(true);
    }

    const navigateTo = (event: any, typeUrl?: string, id?: string) => {
        if (event.ctrlKey) {
            window.open(location.pathname + '/details/' + id, '_blank');
        } else {
            if (typeUrl) {
                navigate(`/group/${typeUrl}/${id}`);
            } else {
                navigate('/groups');
            }
        }
    }

    const onRowClick = (event: any, id?: string) => {
        console.log(event.ctrlKey)
        if (id) {
            if (event.ctrlKey) {
                window.open(location.pathname + '/details/' + id, '_blank');
                return;
            }
            navigate(`/group/details/${id}`);
        }
    }

    const onCancelRemove = () => {
        setItemToRemove(null);
        setShowRemoveModal(false);
    };

    const onRemove = async () => {
        if (itemToRemove === null) {
            toast.error(t('messages.record_delete_error'));
            return;
        }
        try {
            await GroupsService.remove(itemToRemove);
            onCancelRemove();
            toast.success(t('messages.record_delete_success'));
            setCriteria({ ...criteria, page: 1 })
        }
        catch (error) {
            Logger.error(LOGGER_LOG_TYPE.REQUEST, 'Couldn\'t delete groups', error);
            toast.error(t('messages.record_delete_error'));
        }
    };

    useEffect(() => {
        getData().catch(console.error);
    }, [criteria]);

    function handleChange(e: any) {
        const key = e.target.name;
        const value = e.target.value;
        setFilters({ ...filters, [key]: value });
    }
    function clearFilters() {
        setFilters({ allIn: '' });
    }
    const onSearch = () => {
        setCriteria({ ...criteria, allIn: filters.allIn });
    }

    return (
        <ScreenTitle title={t('groups.title')}>
            <Breadcrumb>
                <Breadcrumb.Item active>{t('groups.title')}</Breadcrumb.Item>
            </Breadcrumb>
            <Container fluid>
                <Card className={styles.filters}>
                    <Card.Body>
                        <Stack direction="horizontal" gap={3} >
                            <Form.Control type="text" value={filters.allIn} className="me-auto" name="allIn" onChange={handleChange} placeholder={t('groups.list.search_for')} />
                            <Button variant="secondary" onClick={onSearch}>{t('common.search')}</Button>
                            <div className="vr" />
                            <Button variant="outline-danger" onClick={clearFilters}>{t('common.clear')}</Button>
                            <Button variant="primary" onClick={() => navigate('/group/new/')}>{t('common.new')}</Button>
                        </Stack>
                    </Card.Body>
                </Card>
            </Container>
            <Container fluid>
                <ListingTable
                    columns={tableColumns}
                    rows={groupsPage?.items || []}
                    onRowClick={(row, _, event) => onRowClick(event, row.id)}
                    allowHover={true}
                    initialSearch={{ colField: 'g.created_date', isOrderAsc: false }}
                />
                <PaginationWithInfo
                    itemName={t('groups.title')}
                    currentPage={groupsPage?.currentPage ?? 1}
                    pageItems={groupsPage?.items?.length || 0}
                    totalItems={groupsPage?.totalItems || 0}
                    onChange={page => setCriteria({ ...criteria, page })}
                />
            </Container>
            <QuestionYesNo onNo={onCancelRemove} onYes={onRemove} isVisible={showRemoveModal} message={t('messages.remove_record_with_ident', { name: '' })} />
        </ScreenTitle>
    );
};

export default GroupsScreen;
