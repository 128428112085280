import { LOGGER_LOG_TYPE, STORAGE, TYPE_REQUIREMENT } from 'Config';
import GroupsService from 'api/groups/GroupsService';
import { GroupAccessDto, GroupDto } from 'api/groups/models/GroupDto';
import Loading from 'common/services/Loading';
import Logger from 'common/services/Logger';
import { useEffect, useState } from 'react';
import { Col, Container, Row, Tab, Tabs } from 'react-bootstrap';
import toast from 'react-hot-toast';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import styles from './DocumentsScreen.module.scss';
import 'react-pdf/dist/Page/AnnotationLayer.css';
import 'react-pdf/dist/Page/TextLayer.css';
import PDFViewer from 'common/components/pdf/PDFViewer';
import ScreenTitle from 'common/components/screenTitle/ScreenTitle';
import HistoryBar from './components/HistoryBar';
import AccessModal from './components/AccessModal';
import AccessBar from './components/AccessBar';
import Storage from '../../common/services/Storage';
import NavBar from 'common/layouts/navbar/NavBar';
import { saveAs } from 'file-saver';

type Props = {
};

const DocumentsScreen: React.FC<Props> = () => {
    const { key } = useParams<{ key: string }>();
    const [typeRequirement, setTypeRequirement] = useState<string>();
    const [item, setItem] = useState<GroupDto>();
    const [selected, setSelected] = useState('');
    const [historic, setHistoric] = useState<GroupAccessDto[]>([]);
    const [showPopup, setShowPopup] = useState(false);
    const [showModal, setShowModal] = useState(false);
    const [downloading, setDownloading] = useState(false);

    const { t } = useTranslation();

    const getType = async () => {
        try {
            if (key) {
                Loading.show();
                setTypeRequirement(await GroupsService.getType(key));
            }
        } catch (error) {
            Logger.error(LOGGER_LOG_TYPE.REQUEST, 'Couldn\'t get group type', error);
            toast.error(t('messages.error_load_info'));
            Loading.hide();
        }
    };

    const getData = async () => {
        try {
            if (key) {
                Loading.show();
                const result = await GroupsService.get(key);
                if (result && result.medias) {
                    setSelected(result.medias[0].id ?? '');
                }
                setItem(result);
            }
        } catch (error) {
            Logger.error(LOGGER_LOG_TYPE.REQUEST, 'Couldn\'t get group', error);
            toast.error(t('messages.error_load_info'));
        } finally {
            Loading.hide();
        }
    };

    const refreshHistoricFor = async (item: GroupDto) => {
        try {
            if (item) {
                Loading.show();
                const historic = await GroupsService.getAccessDocumentsHistory(item.id);
                setHistoric(historic);
                const groups: string[] = JSON.parse(Storage.get(STORAGE.LAST_SEEN_GROUPS) ?? '[]');
                const isIn = groups.includes(key!);
                setShowPopup(!!(item) && item.type === TYPE_REQUIREMENT.NOTICE && !isIn);
                // setShowModal(!!(item) && item.type === TYPE_REQUIREMENT.READ && !isIn);
            }
        } catch (error) {
            Logger.error(LOGGER_LOG_TYPE.REQUEST, 'Couldn\'t get group accesses', error);
            toast.error(t('messages.error_load_info'));
        } finally {
            Loading.hide();
        }
    }

    const downloadZip = async () => {
        try {
            setDownloading(true);
            if (item) {
                // const file = await GroupsService.downloadByGroup(item?.id);
                // saveAs(file, `${item?.id}.zip`);
                if (item && item.medias) {
                    for (let i = 0; i < item.medias.length; i++) {
                        const blob = await fetch(item.medias[i]?.url ?? '').then(r => r.blob());
                        saveAs(blob, `${item.medias[i]?.fileName}`);
                    }
                }
            }
        } catch (error) {
            Logger.error(LOGGER_LOG_TYPE.REQUEST, 'Couldn\'t get medias zip', error);
            toast.error(t('messages.error_load_info'));
        } finally {
            setDownloading(false);
        }
    };

    const downloadMedia = async () => {
        try {
            setDownloading(true);
            if (item && item.medias) {
                const media = item.medias?.find(x => x.id === selected);
                // const file = await GroupsService.downloadByMedia(media?.id ?? '');
                 const blob = await fetch(media?.url ?? '').then(r => r.blob());
                 saveAs(blob, `${media?.fileName}`);
            }
        } catch (error) {
            Logger.error(LOGGER_LOG_TYPE.REQUEST, 'Couldn\'t get medias zip', error);
            toast.error(t('messages.error_load_info'));
        } finally {
            setDownloading(false);
        }
    };

    useEffect(() => {
        getType()
            .catch(console.error);
    }, [key]);

    useEffect(() => {
        if (key && typeRequirement) {
            const groups: string[] = JSON.parse(Storage.get(STORAGE.LAST_SEEN_GROUPS) ?? '[]');
            const isIn = groups.includes(key);

            setShowPopup(typeRequirement === TYPE_REQUIREMENT.NOTICE && !isIn);
            setShowModal(typeRequirement === TYPE_REQUIREMENT.READ && !isIn);

            if (isIn || typeRequirement !== TYPE_REQUIREMENT.READ) {
                getData();
            } else {
                Loading.hide();
            }
        }
    }, [typeRequirement]);

    useEffect(() => {
        if (item) {
            refreshHistoricFor(item)
                .catch(console.error);
        }
    }, [item]);

    return (
        <ScreenTitle title={t('documents.title')}>
            <NavBar />
            <Container fluid className={styles.content}>
                {item
                    ? <>
                        <Tabs fill activeKey={selected} onSelect={(k) => setSelected(k ?? '')} className="mb-3" style={{ marginRight: '3rem' }}>
                            {item.medias && item.medias?.map((m, i) => {
                                return (<Tab key={`tab_${i}`} eventKey={m.id} title={<strong>{m.fileName}</strong>}>
                                    <Container fluid className={styles.tabContainer}>
                                        <Row>
                                            <Col md="2"></Col>
                                            <Col xs="11" md="8" className={styles.pdfContent}>
                                                <PDFViewer file={m.url}></PDFViewer>
                                            </Col>
                                            <Col md="2"></Col>
                                        </Row>
                                    </Container>
                                </Tab>)
                            })}
                        </Tabs>

                        <AccessBar show={showPopup} onClick={() => setShowModal(true)} onClose={() => { setShowPopup(false) }} />

                        <HistoryBar downloading={downloading} key={historic?.length} items={historic ?? []} type={item?.type ?? TYPE_REQUIREMENT.NOTICE} onScrollToTop={() => {
                            window.scrollTo(0, 0);
                        }} onDownloadZip={downloadZip} onDownloadMedia={downloadMedia} canDownload={item.canDownload} canSeeHistory={item.canSeeHistory}/>
                    </>
                    : <div style={{ textAlign: 'center' }}>{t('documents.modal.subtitle.READ')}</div>
                }

                {key && typeRequirement && <>
                    <AccessModal type={typeRequirement} groupKey={key} show={showModal} onClose={(canClose) => {
                        if (canClose) {
                            setShowModal(false);
                            if (item) {
                                refreshHistoricFor(item).catch(console.error);
                            } else {
                                getData();
                            }
                        }
                    }} />
                </>}
            </Container>
        </ScreenTitle>
    );
};

export default DocumentsScreen;
